import React from "react";
import slm_logo from "../images/sallie-mae-logo.svg";
import './App.css';


import Container from "@mui/material/Container";

const Header = () => {

  return (
      <Container maxWidth="lg">

        <div className="logo">
          <img src={slm_logo} alt="slm-logo" border="0" className="logo-img"/>
        </div>

      </Container>
  );
};

export default Header;


