import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Dropdown from 'react-dropdown';
import Grid from '@mui/joy/Grid';
import { SelectStateEnum } from './UploadFileSelectorEnum';

// Select File to upload or Remove Selected file
const SelectFileComponent = (props) => {
    
    if (props.componentstate === SelectStateEnum.Select) {
        return (<UploadFileSelector props={props}/>);
    }

    return (<SelectedFileRemover props={props}/>);
}

const UploadFileSelector = (props) => {
    
    const [fileSelected, setFileSelected] = useState(props.props.uploadfile);
    const [errorNoSelectedFile, setErrorNoSelectedFile] = useState(props.props.uploadfile.errors.fileselected);
    const [errorInvalidFileType, setErrorInvalidFileType] = useState(props.props.uploadfile.errors.invalidfiletype);
    const [errorNoDocTypeSelected, setErrorNoDocTypeSelected] = useState(props.props.uploadfile.errors.doctype);

    const handleChangeDocumentType = (option) => {
        setErrorNoDocTypeSelected("");
        setFileSelected({...fileSelected, doctype: option.value, errors: {...fileSelected.errors, doctype: ""}});
    }
   
    const handleFileSelected = (event) => {
        setErrorNoSelectedFile("");
        setErrorInvalidFileType("");
        setFileSelected({
                            ...fileSelected,
                            filetoupload: event.target.files[0],
                            errors: {...fileSelected.errors, fileselected: "", invalidfiletype: ""}
        });
    }

    const handleUploadClick = (_e) => {

        // Note; Any errors are handled locally in this component. But the object is updated in the parent component.
        // This was the most reliable way to bubble the errors back to the parent so it would be preserved on a rerender.
        let isValid = true;
        let _fileSelected = {...fileSelected};  // deep copy

        if ( ! fileSelected.filetoupload ) {
            setErrorNoSelectedFile("No file selected.");
            _fileSelected = {...fileSelected,  errors: {...fileSelected.errors, fileselected: "No file selected."}};
            setFileSelected(_fileSelected);
            isValid = false;
        }
        else
        {
            const fileName  = fileSelected.filetoupload.name;
            const fileType =  fileName.split('.').slice(-1)[0];
            const allowedFileType = 'PDF,HTML,TIFF,JPEG,JPG,TIF';
            
            if (allowedFileType.indexOf(fileType.toUpperCase()) === -1) {
                setErrorInvalidFileType("Invalid file type. Allowed file types: PDF, HTML, TIFF and JPEG");
                _fileSelected = {...fileSelected, errors: {...fileSelected.errors, invalidfiletype: "Invalid file type. Allowed file types: PDF, HTML, TIFF and JPEG"}};
                setFileSelected(_fileSelected);
                isValid = false;
            } else {
                setErrorInvalidFileType("");
            }
        }
        
        if (fileSelected.doctype.length === 0) { 
            setErrorNoDocTypeSelected("Please select a document type.");
            _fileSelected = {...fileSelected, errors: {...fileSelected.errors, doctype: "Please select a document type."}};
            setFileSelected(_fileSelected);
            isValid = false;
        }
        
        if (isValid) {   // no errors
            props.props.onUploadFileSelected(_fileSelected, SelectStateEnum.Remove);
        } else {
            props.props.onUploadFileSelected(_fileSelected, SelectStateEnum.Select);            
        }
    }


    return (
                <Grid container spacing={1}>
                    <Grid item xs={4} sm={4}>
                        <input 
                                className="btn-as-link"
                                type="file" 
                                accept="image/jpeg, image/tiff, application/pdf"  
                                data-testid={props.props.uploadfile.uploadid} 
                                id={props.props.uploadfile.uploadid} 
                                name="avatar" 
                                onChange={handleFileSelected}>
                        </input>
                        { (errorNoSelectedFile.length > 0) && <div className="alert alert-danger">{errorNoSelectedFile}</div> }
                        { (errorInvalidFileType.length > 0) && <div className="alert alert-danger">{errorInvalidFileType}</div> }
                    </Grid>
                    <Grid item xs={5} sm={5}>
                        <Dropdown 
                                className="doctypeselect"
                                options={props.props.documenttypes} 
                                onChange={handleChangeDocumentType} 
                                placeholder="Select document type" 
                        />
                        { (errorNoDocTypeSelected.length > 0) && <div className="alert alert-danger">{errorNoDocTypeSelected}</div> }
                    </Grid>
                    <Grid item xs={3} sm={3}>
                        <button className="btn custom-btn-primary small" name="uploadbtn" onClick={handleUploadClick}>Upload</button>
                    </Grid>
                </Grid>

    );
}

UploadFileSelector.propTypes = {
    props: PropTypes.object.isRequired
  };

const SelectedFileRemover = (props) => {
    
    
    const getDocumentTypeDesc = (docTypeCode) => {
        const documentTypes = props.props.documenttypes;
        const doctype = documentTypes.find(doctypeoption => doctypeoption.value === docTypeCode);

        return (doctype ? doctype.label : "");
    }
    
    const docTypeDesc = getDocumentTypeDesc(props.props.uploadfile.doctype);
    const uploadFileName = props.props.uploadfile.filetoupload.name;
    const fileSelected = props.props.uploadfile;

    const handleRemoveClick = () => {
        
        props.props.onRemoveSelectedFile(fileSelected, SelectStateEnum.Select);
    } 

    return (
                <>
                    <Grid container spacing={1}>
                        <Grid item xs={4} sm={4}>
                            <label htmlFor={props.props.uploadfile.uploadid}><span><b>{uploadFileName}</b></span></label>
                        </Grid>
                        <Grid item xs={5} sm={5}>
                            <label htmlFor={props.props.uploadfile.uploadid}><span><b>{docTypeDesc}</b></span></label>
                        </Grid>
                        <Grid item xs={3} sm={3}>
                            <button className="btn custom-btn-primary small" name="removebtn" onClick={handleRemoveClick}>Remove</button> 
                        </Grid>
                    </Grid>                       
                </>
    )

}

export { UploadFileSelector, SelectedFileRemover, SelectFileComponent };
