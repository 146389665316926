import React from "react";
import PropTypes from "prop-types";
import 'react-dropdown/style.css';
import '../App.css';
import { Link as TextLink } from '@mui/joy';

const textUploadExisting1 = "Thank you for submitting the required documents. Please allow 3-5 business days for us to review. We'll call if\n " +
"additional information is needed and send an email once your request has been completed.\n\n" +
"OpenNet is available 24/7 for real-time loan updates and loan status inquiries.\n\n";
const textUploadExisting2 = "Sallie Mae School Assist\n";
const textUploadExisting3a = "844-8-ASSIST (844-827-7478)\n";
const textUploadExisting3b = "877-456-6221 – School Assist International Number";
const emailExisting = "SchoolAssist@SallieMae.com";
const textUploadExisting4 = "Important: To help safeguard our customers' information, all non-public personal information (NPI) for students,\n " +
 "borrowers, or cosigners, including SSN and Date of Birth, must be sent in an encrypted and secured manner.\n"+
 "NPI should never be in the body of your email correspondence with Sallie Mae Bank.\n\n";


const textUploadProspect1 = "Thanks for submitting the required documentation to review your school's eligibility for Sallie Mae Loan\n " +
 "products. If you think of any questions, please email us at ";
const emailProspect = "relationship_management_support@salliemae.com";
const textUploadProspect2 = "We'll contact you within 24 hours to confirm receipt of your documents.\n\nSallie Mae Relationship Management Team\n\n " +
 "Important: To help safeguard our customers' information, all non-public personal information (NPI) for students,\n " +
 "borrowers, or cosigners, including SSN and Date of Birth, must be sent in an encrypted and secured manner.\n " +
 "NPI should never be in the body of your email correspondence with Sallie Mae Bank.";


const textUploadDeposit1 = "Thanks! Please allow 3-5 business days for us to review your documents.\n";


const uploadFailedMessage = "Upload failed. Please try again later."

const UploadResultMessage = (props) => {
    if (props.uploadresult === "failure") {
        return (
            <div>
                <p className="css-fix">{uploadFailedMessage}</p>
            </div>
        );
    }

    if (props.usertype === "deposit") {
        return (
            <div>
                <p className="css-fix">{textUploadDeposit1}</p>
                <p></p>
            </div>
        );
    }

    if (props.usertype === "existing") {
        return (
            <div>
                <p className="css-fix">{textUploadExisting1}</p>
                <p className="css-fix">{textUploadExisting2}</p>
                <p className="css-nobreakp">{textUploadExisting3a}</p>
                <p className="css-nobreakp">{textUploadExisting3b}</p>
                <p className="css-nobreakp"><TextLink href={"mailto:" + emailExisting} >
                    {emailExisting}
                </TextLink></p>
                <p></p>
                <p></p>
                <p className="css-fix">{textUploadExisting4}</p>
                <p></p>
                <p></p>
            </div>
        );
    }

    // this would be "prospect"
    return (
        <div>
            <p className="css-fix">{textUploadProspect1}<span><TextLink href={"mailto:" + emailProspect}>{emailProspect}</TextLink></span>.</p>
            <p className="css-fix">{textUploadProspect2}</p>
            <p></p>
            <p></p>
            <p></p>
            <br></br>
            <br></br>
        </div>
    );
}

UploadResultMessage.propTypes = {
    uploadresult: PropTypes.string.isRequired,
    usertype: PropTypes.string.isRequired
  };

export default UploadResultMessage;