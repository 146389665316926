import { React, useState, useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { getDepositDocumentTypes } from '../../api/depositDocumentTypes';
import { callRetailUploadApi, appendToFormDepositData } from '../../api/uploadRetailDocuments';
import Typography from '@mui/joy/Typography';
import Stack from '@mui/joy/Stack';
import { TextareaAutosize } from '@mui/base';
import { SelectFileComponent } from '../common/UploadFiles';
import BusySpinner from '../common/BusySpinner';
import { SelectStateEnum } from '../common/UploadFileSelectorEnum';
import sdu_env from '../../config.js';
import { number0, number1, number2, number3, number4, number5, number5p0, number0p0 } from "../../utils/constants";


const DepositUploadPage = () =>  {
  const hDeposit = useHistory();
  const locDeposit = useLocation();

  const [submitBtnDisabledDeposit, setSubmitBtnDisabledDeposit] = useState(false);
  const [isValidDataDeposit, setIsValidDataDeposit] = useState(false);
  const [submitErrorsDeposit, setSubmitErrorsDeposit] = useState('');
  const [uploadFilesDeposit, setUploadFilesDeposit] = useState(Array.from({length: number5}).fill(
      {
        uploadid: 0,
        filetoupload: null,
        doctype: '',
        errors: {doctype: "", fileselected: "", invalidfiletype: ""}
      }
    )
  );

  const depositUploadURL = sdu_env.RETAIL_UPLOAD_API_URL;

  // Component state separate from selected file object since the rerender on state change is controlled by the parent (this page)
  const [uploadComponentStates, setUploadComponentStates] = useState(Array.from({length: number5}).fill(SelectStateEnum.Select));

  const [documentTypes, setDocumentTypes] = useState([]);
  const [totalFileSize, setTotalFileSize] = useState(0.0);
  const [comments, setComments] = useState('');
  const [busy, setBusy] = useState({'display': 'none'});


  // Load the userTypes and set the To attribute on Link component
  useEffect(() => {
    setDocumentTypes(getDepositDocumentTypes('Deposit'));
  }, []);


  // Rerender when an upload file is selected - the option for that particular selected file should be Remove i.e SelectedFileRemover component

  // Call API to upload documnent(s)
  useEffect(() => {
    if (isValidDataDeposit) {
      setBusy({'display': 'inline-block'});
      const formData = appendToFormDepositData(depositUploadURL, locDeposit.state, uploadFilesDeposit, comments);

      callRetailUploadApi(formData)
      .then (result => {
        hDeposit.push('../uploadresult', {state: {uploadresult: 'success', usertype: 'deposit', apiresult: result}});
      })
      .catch(error => {
        hDeposit.push('../uploadresult', {state: {uploadresult: 'failure', usertype: 'deposit', apierror: error}});
      })
      .finally(() => {
        setBusy({'display': 'none'});
      });
    }
  }, [isValidDataDeposit]);





  const fileSelectorErrors = () => {
    let fileSelectorHasErrors = false;

    uploadFilesDeposit.forEach(uploadFile => {
      if (uploadFile.errors.doctype.length > 0 || uploadFile.errors.fileselected.length > 0 || uploadFile.errors.invalidfiletype.length > 0) {
        fileSelectorHasErrors = true;
      }
    });

    return fileSelectorHasErrors;
  };


  const formIsValid  = () => {

    if (fileSelectorErrors()) {
      setSubmitErrorsDeposit('Please correct the errors on the form before clicking Submit.');
      return false;
    }

    if (totalFileSize > number5p0) {
      setSubmitErrorsDeposit('Total size of all files selected for upload cannot be greater than 5.0MB');
      return false;
    }

    if (totalFileSize === number0p0) {
      setSubmitErrorsDeposit('Please choose at least one file to upload before clicking Submit.');
      return false;
    }

    setIsValidDataDeposit(true);
    return true;
  }


  const handleSubmit = event => {
    event.preventDefault();

    if (!formIsValid()) {
      return;
    }

    setSubmitBtnDisabledDeposit(true);
  }


  const handleUploadFileSelected = (uploadFile, componentState) => {
    setSubmitErrorsDeposit('');

    // Set upload files. If no selected file (user removed selection), no point having that item in array.
    // Note that the object is being set to null in this case.
    // This is not the same as 'empty' which is when a user does not even select a file.
    const idx = uploadFile.uploadid - number1;

    // store the state passed from child component
    const _uploadComponentStates = [...uploadComponentStates];
    _uploadComponentStates[idx] = componentState;
    setUploadComponentStates(_uploadComponentStates);

    const _uploadFilesDeposit = [...uploadFilesDeposit]
    _uploadFilesDeposit[idx] = uploadFile;
    setUploadFilesDeposit(_uploadFilesDeposit);

    // Calculate and set total file size for selected files. Note: even though uploadFiles will be the same array,
    // objects within the array (fileselected object) will have changed.
    // The calculateTotalFileSize() method will correctly calculate the total size.
    setTotalFileSize(calculateTotalFileSize(_uploadFilesDeposit));
  }


  const handleRemoveSelectedFile = (uploadFile, componentState) => {
    const idx = uploadFile.uploadid - number1;

    // store the state passed from child component
    const _uploadComponentStates = [...uploadComponentStates];
    _uploadComponentStates[idx] = componentState;
    setUploadComponentStates(_uploadComponentStates);

    // Reset object
    uploadFile.doctype = "";
    uploadFile.filetoupload = null;
    
    const _uploadFilesDeposit = [...uploadFilesDeposit]
    _uploadFilesDeposit[idx] = uploadFile;
    setUploadFilesDeposit(_uploadFilesDeposit);

    // Calculate and set total file size for selected files. Note: even though uploadFiles will be the same array,
    // objects within the array (fileselected object) will have changed.
    // The calculateTotalFileSize() method will correctly calculate the total size.
    setTotalFileSize(calculateTotalFileSize(_uploadFilesDeposit));
  }


  const onTextInputChange = event => {
    event.preventDefault();
    const textValue = event.target.value;
    setComments(textValue);
  };

  const calculateTotalFileSize = (_uploadFilesDeposit) => {
    let totalFileSizeMB = 0.0;
    const number1000000 = 1000000;
  
    _uploadFilesDeposit.forEach(uploadFile => {
      if (uploadFile.filetoupload){
        totalFileSizeMB += (uploadFile.filetoupload.size / number1000000);
      }
    });
  
    // return only 1 digit after decimal point
    return (+totalFileSizeMB.toFixed(number2));
  }


  return (
    <>
      <h1>Secure Document Upload</h1>
      <p>Please upload your documents from the list below and select the document type.</p>
      {/* <h3>Document types for prospect schools</h3> */}

      {/* <DepositDocumentType usertype="Deposit" /> */}

      <p></p>
      <h3>Add documents</h3>

      <Typography>
        Documents must be in an image format such as JPEG, TIFF, or PDF. You can submit up to five documents at a
        time but the combined size of all images being sent can't exceed 5.0MB.
      </Typography>

      <p></p>
      <label ><b>{totalFileSize}</b> of 5.0MB used</label>
      <p></p>

      <Stack spacing={2}>
        <SelectFileComponent
                            uploadfile={{...uploadFilesDeposit[number0], uploadid: number1}}
                            componentstate= {uploadComponentStates[number0]}
                            documenttypes={documentTypes}
                            onUploadFileSelected={handleUploadFileSelected}
                            onRemoveSelectedFile={handleRemoveSelectedFile}
        />
        <SelectFileComponent
                            uploadfile={{...uploadFilesDeposit[number1], uploadid: number2}}
                            componentstate= {uploadComponentStates[number1]}
                            documenttypes={documentTypes}
                            onUploadFileSelected={handleUploadFileSelected}
                            onRemoveSelectedFile={handleRemoveSelectedFile}
        />
        <SelectFileComponent
                            uploadfile={{...uploadFilesDeposit[number2], uploadid: number3}}
                            componentstate= {uploadComponentStates[number2]}
                            documenttypes={documentTypes}
                            onUploadFileSelected={handleUploadFileSelected}
                            onRemoveSelectedFile={handleRemoveSelectedFile}
        />
        <SelectFileComponent
                            uploadfile={{...uploadFilesDeposit[number3], uploadid: number4}}
                            componentstate= {uploadComponentStates[number3]}
                            documenttypes={documentTypes}
                            onUploadFileSelected={handleUploadFileSelected}
                            onRemoveSelectedFile={handleRemoveSelectedFile}
        />
        <SelectFileComponent
                            uploadfile={{...uploadFilesDeposit[number4], uploadid: number5}}
                            componentstate= {uploadComponentStates[number4]}
                            documenttypes={documentTypes}
                            onUploadFileSelected={handleUploadFileSelected}
                            onRemoveSelectedFile={handleRemoveSelectedFile}
        />
      </Stack>

      <h3>Comments</h3>

      <Typography>
        Please add any comments to explain the documents you're sending and why you're sending them. When you're
        finished, select the <b>Submit</b> button to send your documents.
      </Typography>

      <p></p>

      <TextareaAutosize
        style={{ width: 600 }}
        multiline="true"
        minRows={4}
        id="comments"
        type="text"
        placeholder="Comments"
        label=""
        variant="outlined"
        size="sm"
        onChange={onTextInputChange}
      />

      <div className= "form-group" style={{'display': 'flex', 'flexDirection': 'row', 'alignSelf': 'flex-start', padding: '60px 0px'}}>
        <Link to={
                  {
                      pathname: "DepositCustomer",
                      state: {depositCustomerPage: locDeposit.state.depositCustomerPage}
                  }
              }
              className="btn custom-btn-secondary">
            Back
        </Link>
        <button className="btn custom-btn-primary" name="submitbtn" disabled={submitBtnDisabledDeposit} onClick={handleSubmit}>Submit</button>
        <BusySpinner busy={busy} />
      </div>
      { (submitErrorsDeposit.length > 0) && <div className="alert alert-danger">{submitErrorsDeposit}</div> }
    </>
  );
}




export default DepositUploadPage;
