const isValidPhoneNumber = phoneNumber => {
    const phoneNumberRegEx = /^\((\d{3})\) -?(\d{3})-?(\d{4})$/;

    return phoneNumber.match(phoneNumberRegEx);
}

const isValidSchoolCode = schoolCode => {
    const schoolCodeRegEx = /^\d{6}-\d{2}$/;

    return schoolCode.match(schoolCodeRegEx);
}


const isValidEmail = email => {

    const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    return email.match(emailRegex)
}


const validateName = (firstName, lastName) => {
    const number25 = 25;
    const nameerrors = [];

    if (firstName.length > number25) {
        nameerrors.push({ field: 'firstname', message: 'First name cannot exceed 25 characters.' });
    }

    if (lastName.length > number25) {
        nameerrors.push({ field: 'lastname', message: 'Last name cannot exceed 25 characters.' });
    }

    return nameerrors;
}


const isNumeric = schoolCode => {

    const regexNumeric =  /^\d+$/;
    return (schoolCode.match(regexNumeric));
};


const checkFormErrors = (schoolInfo, schoolOfficialInfo) => {

    const _formErrors = {};

    if (!schoolInfo.schoolname) {
        _formErrors.schoolname = "School name is required.";
    }

    if (!schoolInfo.schoolcode) {
        _formErrors.schoolcode = "School code is required.";
    } else if (!isValidSchoolCode(schoolInfo.schoolcode)) {
            _formErrors.schoolcode = "School code must be in the following format: ######-##";
    }

    if (!schoolOfficialInfo.firstname) {
        _formErrors.firstname = "First name is required";
    }

    if (!schoolOfficialInfo.lastname) {
        _formErrors.lastname = "Last name is required";
    }
    if (!schoolOfficialInfo.emailaddress) {
        _formErrors.emailaddress = "Email address is required";
    }
    if (!schoolOfficialInfo.phonenumber) {
        _formErrors.phonenumber = "Phone number is required";
    }

    // Last name, first name validated
    const errors1 = validateName(schoolOfficialInfo.firstname, schoolOfficialInfo.lastname);
    errors1.forEach((errorItem) => {
        _formErrors[errorItem.field] = errorItem.message;
    });

    // Email field validated
    if (!isValidEmail(schoolOfficialInfo.emailaddress)) {
        _formErrors.emailaddress = "Invalid email address.";
    }

    // Phone number validated. Validates the following format:
    // (XXX) XXX-XXXX
    if (!isValidPhoneNumber(schoolOfficialInfo.phonenumber)) {
        _formErrors.phonenumber = "Invalid phone number";
    }

    return _formErrors;

}

const checkDepositFormErrors = (depositCustomerInfo) => {

    const NINE = 9;
    let _formErrors = {};

    if (!depositCustomerInfo.firstname) {
        _formErrors.firstname = "First name is required";
    }

    if (!depositCustomerInfo.lastname) {
        _formErrors.lastname = "Last name is required";
    }

    if (!depositCustomerInfo.socialsecuritynumber) {
        _formErrors.socialsecuritynumber = "Social Security number is required";
    }

    const ssn = depositCustomerInfo.socialsecuritynumber.replace(/-/g, "").slice(0, NINE);
    if (ssn.length !== NINE) {
        _formErrors.socialsecuritynumber = "Social Security number must be 9 digits";
    }

    // Last name, first name validated
    const errors1 = validateName(depositCustomerInfo.firstname, depositCustomerInfo.lastname);
    errors1.forEach((errorItem) => {
        _formErrors[errorItem.field] = errorItem.message;
    });

    _formErrors = checkDepositOptionalFieldErrors(_formErrors, depositCustomerInfo);

    return _formErrors;
}

const checkDepositOptionalFieldErrors = (_formErrors, depositCustomerInfo) => {
    const SIX = 6;
    // Account number validated
    if (depositCustomerInfo.accountnumber.length > 0 && depositCustomerInfo.accountnumber.length < SIX) {
        _formErrors.accountnumber = "Account number must be at least 6 digits";
    }

    // Email field validated
    if (depositCustomerInfo.emailaddress !== "" && !isValidEmail(depositCustomerInfo.emailaddress)) {
         _formErrors.emailaddress = "Invalid email address.";
    }

    // Phone number validated. Validates the following format:
    // (XXX) XXX-XXXX
    if (depositCustomerInfo.phonenumber.length > 0 && !isValidPhoneNumber(depositCustomerInfo.phonenumber)) {
        _formErrors.phonenumber = "Invalid phone number";
    }

    return _formErrors;
}

export { isValidPhoneNumber, isValidEmail, validateName, checkFormErrors, checkDepositFormErrors };