import React from "react";
import { Link, useLocation } from "react-router-dom";
import 'react-dropdown/style.css';
import '../App.css';
import UploadResultMessage from '../common/UploadResultMessage';

const DepositUploadResultPage = () =>  {
    const activeFontFamilyDeposit = { font: "graphik-light"};
    const locationDeposit = useLocation();
    const showUploadMoreDeposit = (locationDeposit.state.state.uploadresult === 'success');
    const uploadResultMessageDeposit = new Map( [["success", "Upload successful"], ["failure", "Upload failed"]] );

    return (
        <div>
            <h1 style={activeFontFamilyDeposit} >{uploadResultMessageDeposit.get(locationDeposit.state.state.uploadresult)}</h1>
            <br></br>
            <UploadResultMessage usertype={locationDeposit.state.state.usertype} uploadresult={locationDeposit.state.state.uploadresult}></UploadResultMessage>
            { showUploadMoreDeposit ? <UploadMoreLinkBtnDeposit /> : null }
        </div>
    );
}

const UploadMoreLinkBtnDeposit = () => (
    <div  style={{'display': 'flex', 'flexDirection': 'row', 'alignSelf': 'flex-end'}}>
        <Link to={"/"} className="btn custom-btn-primary small">
            Upload more documents
        </Link>
    </div>
);

export default DepositUploadResultPage;
