import React from "react";
import { useState, useEffect } from 'react';
import { Link as ReactRouterLink, useLocation } from "react-router-dom";
import { Link as TextLink } from '@mui/joy';
import Typography from '@mui/joy/Typography';
import TextInput from "./common/TextInput";
import { checkFormErrors } from "../utils/Validation"
import { formatPhoneNumber, formatSchoolCode } from "../utils/Formatting"
const SchoolOfficialInfoPage = (props) => {

  const location = useLocation();

  const userType = (props.match.params.usertype.charAt(0).toUpperCase() + props.match.params.usertype.slice(1));
  const [errors, setErrors] = useState({});
  const isDisplayCluid = (userType.localeCompare('Existing') === 0);

  const divStyle = {
    overflowY: 'auto',
    fontSize: '12px'
  };

  // School official information
  const [schoolOfficialInfo, setSchoolOfficialInfo] = useState(
    {
      firstname: "",
      lastname: "",
      emailaddress: "",
      phonenumber: ""

    }
  );

  // School information
  const [schoolInfo, setSchoolInfo] = useState(
    {
      schoolname: "",
      schoolcode: "",
      cluid: "",
      schooladdress: "",
      usertype: userType
    }
  );


  useEffect(() => {
    const _schoolInfo = (location.state === undefined ? null : location.state.schoolinfo);                  // from the location object - if this page was displayed by clicking on Back from Upload page
    const _schoolOfficialInfo = (location.state === undefined ? null : location.state.schoolofficialinfo)

    if (_schoolInfo) {
      setSchoolInfo(_schoolInfo);
    }

    if (_schoolOfficialInfo) {
      setSchoolOfficialInfo(_schoolOfficialInfo);
    }

  }, [location.state]);


  const isFormValid = () => {
    const _formErrors = checkFormErrors(schoolInfo, schoolOfficialInfo);
    setErrors(_formErrors);
    return Object.keys(_formErrors).length === 0;
  }


  const resetError = (fieldName) => {
    const _errors = { ...errors };
    delete _errors[fieldName];
    setErrors(_errors);
  }


  const handleClick = (e) => {

    if (!isFormValid()) {
      e.preventDefault(); // prevent user from navigating to next page
      return;
    }


  }


  const handleSchoolInfoChange = ({ target }) => {

    // Reset error if there is one
    if (errors[target.name]) { 
      resetError(target.name); 
    }

    // Store the keyed in value in state variable
    setSchoolInfo({
      ...schoolInfo,
      [target.name]: target.value
    });
  }


  const handleSchoolOfficialInfoChange = ({ target }) => {

    // Reset error if there is one
    if (errors[target.name]) { 
      resetError(target.name); 
    }

    // Store the keyed in value in state variable
    setSchoolOfficialInfo({
      ...schoolOfficialInfo,
      [target.name]: target.value
    });

  }

  const handlePhoneNumberChange = ({ target }) => {
    // Reset error if there is one
    if (errors[target.name]) {
      resetError(target.name);
    }

    // Store the keyed in value in state variable
    setSchoolOfficialInfo({
      ...schoolOfficialInfo,
      [target.name]: formatPhoneNumber(target.value)
    });
  }

  const handleSchoolCodeChange = ({ target }) => {
    // Reset error if there is one
    if (errors[target.name]) {
      resetError(target.name);
    }

    // Store the keyed in value in state variable
    setSchoolInfo({
      ...schoolInfo,
      [target.name]: formatSchoolCode(target.value)
    });
  }

  
  return (
      <div style={divStyle}>
        <h1>Secure Document Upload</h1>
        <h2>{userType + " school official"}</h2>
        
        <h3>Your information</h3>

        <TextInput
          id="firstname"
          label="Your first name"
          name="firstname"
          onChange={handleSchoolOfficialInfoChange}
          value={schoolOfficialInfo.firstname}
          error={errors.firstname}
          classname="textinput"
        />
        <TextInput
          id="lastname"
          label="Your last name"
          name="lastname"
          onChange={handleSchoolOfficialInfoChange}
          value={schoolOfficialInfo.lastname}
          error={errors.lastname}
          classname="textinput"
        />
        <TextInput
          id="emailaddress"
          label="Your email address"
          name="emailaddress"
          onChange={handleSchoolOfficialInfoChange}
          value={schoolOfficialInfo.emailaddress}
          error={errors.emailaddress}
          classname="textinput"
          maxLength={320}
        />
        <TextInput
          id="phonenumber"
          label="Your phone number"
          name="phonenumber"
          onChange={handlePhoneNumberChange}
          value={schoolOfficialInfo.phonenumber}
          error={errors.phonenumber}
          classname="textinput-sml"
        />
        <p />

        <h3>School information</h3>
        <p />
        <TextInput
          id="schoolname"
          label="School name"
          name="schoolname"
          onChange={handleSchoolInfoChange}
          value={schoolInfo.schoolname}
          error={errors.schoolname}
          classname="textinput"          
        />
        <TextInput
          id="schoolcode"
          label="School code"
          name="schoolcode"
          onChange={handleSchoolCodeChange}
          value={schoolInfo.schoolcode}
          error={errors.schoolcode}
          classname="textinput-xsml"          
        />
        <TextInput
          id="cluid"
          show={isDisplayCluid}
          label="CLUID (optional)"
          name="cluid"
          onChange={handleSchoolInfoChange}
          value={schoolInfo.cluid}
          error={errors.cluid}
          classname="textinput-xsml"          
        />
        <TextInput
          id="schooladdress"
          label="School mailing address (optional)"
          name="schooladdress"
          onChange={handleSchoolInfoChange}
          value={schoolInfo.schooladdress}
          error={errors.schooladdress}
          showtextarea={true}
          classname="textinput"
        />
        <p />
        <div style={{ 'display': 'flex', 'flexDirection': 'row', 'alignSelf': 'flex-end' }}>
          <ReactRouterLink to={"/"} className="btn custom-btn-primary">
            Back
          </ReactRouterLink>
          <ReactRouterLink to={
            {
              pathname: userType + "/upload",
              state: {
                schoolinfo: schoolInfo,
                schoolofficialinfo: schoolOfficialInfo
              }
            }
          }
            onClick={handleClick} className="btn custom-btn-primary">
            Continue
          </ReactRouterLink>
        </div>
      </div>
  );

}

export default SchoolOfficialInfoPage;